import { UpdateJobPermissions } from './UpdateJobPermissions.js';

/**
 * Creates a new draft pole application.
 *
 * @param {object} options - Options object
 * @param {string} options.userGroup - The user group of the user creating the draft.
 * @param {string} options.utilityCompany - The utility company the draft will be submitted to.
 * @param {string} options.appType - The type of application the draft will be.
 * @param {object} [options.metadata] - The metadata to be added to the draft.
 * @param {object} [options.userRecord] - The user record of the user creating the draft.
 * @param {object} [options.firebase] - The firebase instance to use (uses globalThis.firebase if not provided).
 * @param {(jobId: string) => Promise<void>} [options.draftCreatedAction] - The "Draft Created" callback action to run (must be provided in a Node context).
 * @param {array|object} [options.sharing] - Companies to share the draft with.
 * @param {object} [options.jobStyles] - The map styles to be added to the draft.
 * @param {object} [options.jobData] - The job data to be added to the draft.
 *
 * @returns {Promise<{jobId: string}>} The job id of the created draft.
 */
export async function CreateDraft(options) {
  // Validate options.
  if (!options || typeof options != 'object') throw new Error('Missing options.');
  if (!options?.userGroup) throw new Error('Missing userGroup.');
  if (!options?.utilityCompany) throw new Error('Missing utilityCompany.');
  if (!options?.appType) throw new Error('Missing appType.');

  // Get a reference to the firebase instance.
  /** @type {import('firebase').default} */
  const firebase = options.firebase ?? globalThis.firebase;

  // Get a reference to the database (prefer FirebaseWorker if we have it).
  /** @type {import('firebase').default.database.Database} */
  const db = typeof globalThis.FirebaseWorker != 'undefined' ? globalThis.FirebaseWorker.database() : firebase.database();
  if (!db) throw new Error('Missing firebase instance.');

  // Setup firebase references.
  const utilityCompanyRef = db.ref(`photoheight/company_space/${options.utilityCompany}`);
  const portalConfigRef = utilityCompanyRef.child('portal_config');

  // Get draft number.
  const draftCounter = await portalConfigRef.child('app_draft_number_counter').transaction((counter) => counter + 1);

  if (!draftCounter.committed) throw new Error('Failed to create draft.');

  // Create new job name.
  const jobName = `Draft ${draftCounter.snapshot.val()}`;

  const appTypeSnapshot = await portalConfigRef.child('app_types').orderByChild('type').equalTo(options.appType).once('value');
  const appTypeConfig = Object.values(appTypeSnapshot.val() || {})[0];
  const appAttributes = appTypeConfig?.app_attributes ?? {};

  let trackingModelLookup = await utilityCompanyRef
    .child('models/action_tracking/_tracking_model_lookup')
    .once('value')
    .then((s) => s.val() || null);

  // Initial Job Metadata.
  const metadata = {
    app_status: 'Draft',
    pole_count: 0,
    creator: options.userGroup,
    app_type: options.appType,
    tracking_model:
      trackingModelLookup?.[options.appType] ??
      (await utilityCompanyRef
        .child('models/action_tracking/_default')
        .once('value')
        .then((s) => s.val() || null)),
    ...options.metadata
  };

  // Seed address metadata if user record is provided.
  if (options.userRecord) {
    const appAttributesList = Object.values(appAttributes ?? {}).map((x) => x.attribute);
    if (appAttributesList.includes('billing_address') && options.userRecord.address) {
      metadata.billing_address =
        `${options.userRecord.address.street_line_1 || ''}\n` +
        `${options.userRecord.address.street_line_2 ? options.userRecord.address.street_line_2 + '\n' : ''}` +
        `${options.userRecord.address.city || ''}, ${options.userRecord.address.state || ''} ${options.userRecord.address.zip || ''}`;
    }
    if (appAttributesList.includes('contact_number') && options.userRecord.phone_number) {
      metadata.contact_number = options.userRecord.phone_number;
    }
    if (appAttributesList.includes('contact_name') && options.userRecord.name) {
      metadata.contact_name = `${options.userRecord.name.first || ''} ${options.userRecord.name.last || ''}`;
    }
  }

  // Generate Job Id.
  const jobId = String(db.ref().push().key);
  // Create Job Update Statement.
  const update = {};
  update[`jobs/${jobId}`] = {
    name: jobName,
    date_created: firebase.database.ServerValue.TIMESTAMP,
    job_creator: options.utilityCompany,
    job_owner: options.utilityCompany,
    project_folder: options.userGroup,
    sharing: {},
    map_styles: options.jobStyles ?? (await getDefaultMapStyles(utilityCompanyRef)),
    metadata,
    ...options.jobData
  };

  // Get the passed-in companies to share with.
  const companiesToShareWith = options.sharing ? (Array.isArray(options.sharing) ? options.sharing : Object.keys(options.sharing)) : [];

  // Build the sharing list (without duplicates)
  const sharing = [...new Set([options.utilityCompany, options.userGroup, ...companiesToShareWith])];

  const jobUpdate = {
    date_shared: firebase.database.ServerValue.TIMESTAMP,
    name: jobName,
    permission: 'write',
    status: 'active',
    metadata,
    job_creator: options.utilityCompany,
    job_owner: options.utilityCompany
  };
  const updateSettings = { updatePath: 'photoheight', wipeExistingData: true, sharing, firebase, update };
  await UpdateJobPermissions(jobId, options.userGroup, jobUpdate, updateSettings);

  for (const companyId of sharing) {
    update[`jobs/${jobId}`].sharing[companyId] = 'write';
    update[`company_space/${companyId}/project_folders/${options.utilityCompany}/jobs/${jobId}`] = true;
  }

  // Update the job.
  await db.ref('photoheight').update(update);

  // Get the "Draft Created" callback action.
  const draftCreatedAction = options.draftCreatedAction ?? getClientHttpsCallable(firebase);
  if (typeof draftCreatedAction != 'function') throw new Error('Invalid "Draft Created" callback action.');

  // Run the "Draft Created" callback action.
  await draftCreatedAction(jobId);

  // Return the job id.
  return { jobId };
}

async function getDefaultMapStyles(utilityCompanyRef) {
  const mapStyles = await utilityCompanyRef
    .child('models/map_styles')
    .once('value')
    .then((s) => s.val());
  const defaultStyle = Object.values(mapStyles).find((style) => style._default);
  return defaultStyle ? { default: defaultStyle } : {};
}

function getClientHttpsCallable(firebase) {
  if (firebase?.functions == null) throw new Error('Firebase functions are not available.');
  const takePortalActions = firebase.functions().httpsCallable('takePortalActionsClient_v2');
  // Return a function that, when called, will run the "Draft Created" callback action.
  return async (jobId) => await takePortalActions({ jobKey: jobId, sourceType: 'DRAFT_CREATED' });
}
