import { LitElement, html, css, nothing, unsafeCSS } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { map } from 'lit/directives/map.js';
import { choose } from 'lit/directives/choose.js';
import { when } from 'lit/directives/when.js';
import { PaperTableStyle } from '../style-modules/paper-table-style.js';
import { FlexStyle } from '../style-modules/flex.js';
import '@polymer/paper-spinner/paper-spinner-lite.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-toggle-button/paper-toggle-button.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/iron-collapse/iron-collapse.js';
import '../katapult-elements/katapult-icon.js';
import '../katapult-elements/katapult-button.js';
import '../katapult-drop-down/katapult-drop-down.js';
import '../input-element/input-element.js';
import '../paper-table/paper-table.js';
import { capitalCase } from 'change-case';

/**
 * Input for filtering applications in the application overview
 *
 * @fires filters-updated - Fired when the filters are updated
 */
class AppFilterInput extends LitElement {
  static styles = [
    // TODO: Move styles to style modules with Lit CSS tagging
    unsafeCSS(PaperTableStyle),
    unsafeCSS(FlexStyle),
    css`
      #searchFilterContainer {
        width: 400px;
        border-radius: 16px;
        transition: all 0.3s;
        background-color: white;
      }
      #searchFilterContainer[opened] {
        /* From: @apply --shadow-elevation-4dp; */
        box-shadow:
          0 4px 5px 0 rgba(0, 0, 0, 0.14),
          0 1px 10px 0 rgba(0, 0, 0, 0.12),
          0 2px 4px -1px rgba(0, 0, 0, 0.4);
      }
      #searchFilterContainer > iron-collapse {
        border-top: 1px solid var(--paper-grey-200);
      }
      #searchContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: inherit;
        padding: 4px;
        box-sizing: border-box;
      }
      #searchContainer > paper-input {
        flex-grow: 1;
      }
      #searchContainer > iron-icon {
        margin: 8px;
      }

      paper-spinner-lite.search-icon {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
        --paper-spinner-color: var(--secondary-color);
      }

      #filterToggle {
        transition: color 0.3s;
      }

      .filter-disclaimer {
        font-size: 10pt;
        padding: 8px 16px;
        text-align: center;
      }

      .filterItem {
        position: relative;
        padding: 12px;
        border-radius: 16px;
        transition:
          background-color 0.2s,
          border 0.2s;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0);
      }
      .filterItem:hover {
        background-color: var(--paper-grey-50);
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      .filterItem input-element {
        --input-element-paper-checkbox-checkmark-color: white;
      }

      .option paper-cell {
        font-size: 8pt;
      }
      .option paper-cell[active] {
        background-color: var(--secondary-color);
        color: white;
      }
    `
  ];

  static properties = {
    showAdvancedFilters: { type: Boolean, reflect: true },
    filteringJobs: { type: Boolean },
    filterAttributes: { type: Array },
    filterAttributeValues: { type: Object },
    searchString: { type: String },
    filteredOverviewAttributes: { type: Array },
    companyAttributes: { type: Object },
    utilityCompany: { type: String },
    userGroup: { type: String },
    showAllJobAttributes: { type: Boolean },
    attachers: { type: Object },
    portalAppStatuses: { type: Array },
    filterOnAdminStrategy: { type: Boolean },
    companies: { type: Object }
  };

  constructor() {
    super();
    this.showAdvancedFilters = false;
    this.filteringJobs = false;
    this.filterAttributes = [];
    this.filterAttributeValues = {};
    this.searchString = '';
    this.filteredOverviewAttributes = [];
    this.companyAttributes = {};
    this.utilityCompany = '';
    this.userGroup = '';
    this.showAllJobAttributes = false;
    this.attachers = {};
    this.portalAppStatuses = [];
    this.filterOnAdminStrategy = false;
    this.companies = {};
    this.attributeList = [];

    this.showCompletedApps = false;
    this.showDrafts = false;
    this.showArchived = false;

    this._updateSearchStringTimeout = null;
    this._updateFilterAttributeTimeout = null;
  }

  static get specialAttributes() {
    return [{ attribute: 'app_status', label: 'Status' }];
  }

  render() {
    return html`
      <div id="searchFilterContainer" ?opened="${this.showAdvancedFilters}">
        <div id="searchContainer">
          <div style="position: relative; margin: 8px;">
            <katapult-icon
              icon="search"
              style="transition: opacity 0.3s ease; ${styleMap({ opacity: this.filteringJobs ? 0 : 1 })}"
            ></katapult-icon>
            <paper-spinner-lite class="search-icon" ?active="${this.filteringJobs}"></paper-spinner-lite>
          </div>
          <paper-input label="Search Applications" no-label-float @value-changed=${this.debouncedSetSearchString}></paper-input>
          <katapult-button
            id="filterToggle"
            icon="filter_list"
            iconOnly
            noBorder
            textColor="${Object.values(this.filterAttributeValues).some((x) => x)
              ? 'var(--secondary-color)'
              : 'var(--primary-text-color-faded)'}"
            title="Advanced Filters"
            @click="${() => (this.showAdvancedFilters = !this.showAdvancedFilters)}"
          ></katapult-button>
        </div>
        <iron-collapse ?opened="${this.showAdvancedFilters}">
          <div style="padding: 12px;">
            ${when(
              this.filterOnAdminStrategy,
              () => html`<div class="filter-disclaimer">(Filtering based on utility administration strategy)</div>`
            )}
            <div class="filterItem">
              <paper-toggle-button @checked-changed="${(e) => this.setAppFilter('completed', e.detail.value)}"
                >Show Completed/Canceled Apps</paper-toggle-button
              >
            </div>
            <div class="filterItem">
              <paper-toggle-button @checked-changed="${(e) => this.setAppFilter('drafts', e.detail.value)}"
                >Show Drafts</paper-toggle-button
              >
            </div>
            <div class="filterItem">
              <paper-toggle-button @checked-changed="${(e) => this.setAppFilter('archived', e.detail.value)}"
                >Show Archived Apps</paper-toggle-button
              >
            </div>
            <katapult-drop-down
              style="margin: 0px 12px;"
              select-on-focus
              no-label-float
              show-last-results
              label="Add Attribute Filter"
              .items="${this.attributeList}"
              value-path="attribute"
              .labelFunction="${(item) => this.getAttributeLabel(item)}"
              @value-changed="${this.addAttributeFilter}"
            >
              <paper-item @click="${() => (this.showAllJobAttributes = !this.showAllJobAttributes)}">
                ${!this.showAllJobAttributes ? 'Show All Job Attributes' : 'Hide Job Attributes'}
              </paper-item>
            </katapult-drop-down>

            ${map(
              this.filterAttributes,
              (attribute) => html`
                <div class="filterItem">
                  <div flex align-center>
                    <div grow style="font-weight: bold; color: var(--primary-text-color-faded);">${this.getAttributeLabel(attribute)}</div>
                    <div>
                      <paper-table rounded outline overflow-hidden pointer>
                        <paper-row class="option" micro>
                          <paper-cell
                            shrink
                            ?active="${this.filterAttributeValues[attribute.$key] == '$$exists'}"
                            @click="${() => this.setAttributeFilter(attribute.$key, '$$exists')}"
                          >
                            <span>Exists</span>
                          </paper-cell>
                          <paper-cell
                            shrink
                            ?active="${this.filterAttributeValues[attribute.$key] == '$$notexists'}"
                            @click="${() => this.setAttributeFilter(attribute.$key, '$$notexists')}"
                          >
                            <span>Doesn't Exist</span>
                          </paper-cell>
                        </paper-row>
                      </paper-table>
                    </div>
                    <katapult-button
                      class="iconButton"
                      icon="clear"
                      iconOnly
                      noBorder
                      noBackground
                      @click="${() => this.removeAttributeFilter(attribute.$key)}"
                    ></katapult-button>
                  </div>

                  ${!['$$exists', '$$notexists'].includes(this.filterAttributeValues[attribute.$key])
                    ? html`${choose(
                        attribute.$key,
                        [
                          [
                            'app_status',
                            () => html`
                              <katapult-drop-down
                                label="Status"
                                no-label-float
                                show-last-results
                                .items="${this.portalAppStatuses}"
                                label-path="status"
                                value-path="status"
                                secondary-label-path="appType"
                                .sort="${(a, b) => a.order - b.order}"
                                @selected-changed="${(e) => this.setAttributeFilter('app_status', e.detail.value)}"
                              ></katapult-drop-down>
                              <div style="font-size: 8pt;">
                                Note: This filter lists statuses from all app types for visibility but does not filter apps by type. Use an
                                "App Type" attribute filter to limit results to an exact app type.
                              </div>
                            `
                          ],
                          [
                            'attachment_owner',
                            () => html`
                              <katapult-drop-down
                                label="${attribute.label}"
                                no-label-float
                                show-last-results
                                .items="${Object.entries(this.attachers)}"
                                label-path="1.name"
                                value-path="0"
                                @selected-changed="${(e) => this.setAttributeFilter('attachment_owner', e.detail.value)}"
                              ></katapult-drop-down>
                            `
                          ],
                          [
                            'creator',
                            () => html`
                              <katapult-drop-down
                                label="${attribute.label}"
                                no-label-float
                                show-last-results
                                .items="${this.companies}"
                                label-path="name"
                                value-path="$key"
                                @selected-changed="${(e) => this.setAttributeFilter('creator', e.detail.value)}"
                              ></katapult-drop-down>
                            `
                          ]
                        ],
                        () =>
                          html`${when(
                            attribute.gui_element == 'date',
                            () => html`
                              <input-element
                                label="Start Date (MM/DD/YYYY)"
                                hide-attribute-name
                                no-label-float
                                .attribute_name="${attribute.$key}"
                                .model="${attribute}"
                                .otherAttributes=${this.companyAttributes}
                                .jobCreator=${this.utilityCompany}
                                .userGroup=${this.userGroup}
                                @value-changed="${(e) => this.setAttributeFilter(`${attribute.$key}._start`, e.detail.value)}"
                              ></input-element>
                              <input-element
                                label="End Date (MM/DD/YYYY)"
                                hide-attribute-name
                                no-label-float
                                .attribute_name="${attribute.$key}"
                                .model="${attribute}"
                                .otherAttributes=${this.companyAttributes}
                                .jobCreator=${this.utilityCompany}
                                .userGroup=${this.userGroup}
                                @value-changed="${(e) => this.setAttributeFilter(`${attribute.$key}._end`, e.detail.value)}"
                              ></input-element>
                            `,
                            () => html`
                              <input-element
                                hide-attribute-name
                                no-label-float
                                checkbox-labels
                                ignore-read-only
                                .attribute_name="${attribute.$key}"
                                .model="${attribute}"
                                .otherAttributes=${this.companyAttributes}
                                .jobCreator=${this.utilityCompany}
                                .userGroup=${this.userGroup}
                                @input="${(e) =>
                                  this.debouncedSetAttributeFilter(attribute.$key, e.target?.shadowRoot.querySelector('#element')?.value)}"
                                @value-changed="${(e) => this.setAttributeFilter(attribute.$key, e.detail.value)}"
                              ></input-element>
                            `
                          )} `
                      )}`
                    : nothing}
                </div>
              `
            )}
          </div>
        </iron-collapse>
      </div>
    `;
  }

  willUpdate(changedProperties) {
    // Update the attribute list
    if (changedProperties.has('showAllJobAttributes') || changedProperties.has('filteredOverviewAttributes')) {
      const attributesList = [...this.filteredOverviewAttributes];
      if (this.showAllJobAttributes) {
        const jobAttributeEntries = Object.entries(this.companyAttributes).filter(([key, value]) =>
          value.attribute_types?.includes?.('job')
        );
        const jobAttributes = jobAttributeEntries.map(([key, value]) => ({ attribute: key, label: value.label }));

        // For each job attribute, add it to the list if it doesn't already exist
        jobAttributes.forEach((attribute) => {
          if (!attributesList.some((x) => x.attribute == attribute.attribute)) attributesList.push(attribute);
        });
      }

      // If the attribute list is missing any special attributes, add them
      for (const specialAttribute of AppFilterInput.specialAttributes) {
        if (!attributesList.some((x) => x.attribute == specialAttribute.attribute)) attributesList.push(specialAttribute);
      }

      // Set the attribute list
      this.attributeList = attributesList;
    }
  }

  dispatchFiltersUpdatedEvent() {
    const event = new CustomEvent('filters-updated', {
      detail: {
        value: {
          searchString: this.searchString,
          showCompletedApps: this.showCompletedApps,
          showDrafts: this.showDrafts,
          showArchived: this.showArchived,
          filterAttributeValues: this.filterAttributeValues
        }
      }
    });
    this.dispatchEvent(event);
  }

  debouncedSetSearchString(e) {
    if (this._updateSearchStringTimeout != null) clearTimeout(this._updateSearchStringTimeout);
    this._updateSearchStringTimeout = setTimeout(() => {
      this.searchString = e.detail.value;
      this.dispatchFiltersUpdatedEvent();
    }, 200);
  }

  setAppFilter(filterType, value) {
    switch (filterType) {
      case 'completed':
        this.showCompletedApps = value;
        break;
      case 'drafts':
        this.showDrafts = value;
        break;
      case 'archived':
        this.showArchived = value;
        break;
    }
    this.dispatchFiltersUpdatedEvent();
  }

  addAttributeFilter(e) {
    const attribute = e.detail.value;
    // If the attribute identifier is defined and the filter attribute is not already being used
    if (attribute && !this.filterAttributes.some((x) => x.$key == e.detail.value)) {
      // Get the attribute model and push it to filter attributes
      const model = this.companyAttributes[attribute];
      const newAttribute = { label: e.target.selectedItem?.label, ...model, $key: e.detail.value };
      this.filterAttributes = [...this.filterAttributes, newAttribute];
    }

    // Clear the dropdown selection
    e.target.value = '';
  }

  removeAttributeFilter(attribute) {
    // Remove the attribute from the filter attributes list
    this.filterAttributes = this.filterAttributes.filter((x) => x.$key != attribute);
    // Remove the attribute from the filter attribute values
    this.setAttributeFilter(attribute, null);
  }

  setAttributeFilter(attributePath, value) {
    const [attribute, subProperty] = attributePath.split('.');
    // Set the filter attribute value
    const currentValue = this.filterAttributeValues[attribute];
    // Re-selecting the same "existence" value should clear the filter
    if (currentValue == value && ['$$exists', '$$notexists'].includes(value)) value = null;
    // If there is a sub property, apply the value to it
    if (subProperty) value = { ...currentValue, [subProperty]: value };

    this.filterAttributeValues = { ...this.filterAttributeValues, [attribute]: value };
    this.dispatchFiltersUpdatedEvent();
  }

  debouncedSetAttributeFilter(attribute, value) {
    if (this._updateFilterAttributeTimeout != null) clearTimeout(this._updateFilterAttributeTimeout);
    this._updateFilterAttributeTimeout = setTimeout(() => {
      this.setAttributeFilter(attribute, value);
    }, 200);
  }

  getAttributeLabel(attribute) {
    const attributeName = attribute?.attribute ?? attribute?.$key;
    return attribute?.label ?? this.companyAttributes?.[attributeName]?.label ?? capitalCase(attributeName ?? '');
  }
}

window.customElements.define('app-filter-input', AppFilterInput);
